import { Injectable, computed, effect, inject, signal } from "@angular/core";
import { AuthHttpClient } from "../auth/auth-client.service";
import { GetSubscriptionUsageDto } from "@libs/shares/dto/getActiveSubscriptionUsageDto.dto";
import { CreateSubscriptionRequestDto } from "@libs/shares/dto/createSubscriptionRequest.dto";
import { SubscriptionDto, SubscriptionStatus } from "@libs/shares/dto/subscription.dto";
import { environment } from "../../../environments/environment";
import { firstValueFrom, tap } from "rxjs";
import { ConsumptionPlan } from "@libs/shares/models/subscription.model";
import { createComputed } from "ngxtension/create-signal";

const mappedName: Record<string, string> = {
  orderbook: "Orderbook API",
  swap: "Swap API",
  balance: "Balance API",
  price: "Price API",
  token: "Token API",
  "tx-gateway": "Transaction API",
  portfolio: "Portfolio API",
  "gas-price": "Gas Price API",
  nft: "NFT API",
  traces: "Traces API",
  fusion: "Fusion API",
  history: "History API",
  default: "Rest of the APIs",
  web3: "Web3 RPC API"
};

@Injectable({
  providedIn: "root"
})
export class SubscriptionService {
  private authHttpClient = inject(AuthHttpClient);
  private activeSubscriptionUsageData = signal<GetSubscriptionUsageDto>({
    productUsage: [],
    renewalDate: new Date(),
    endDate: null,
    id: ""
  });
  subscriptions = signal<SubscriptionDto[]>([]);

  canChangePlan = computed<boolean>(() => {
    const activeSubscription = this.activeSubscription();
    const pendingSubscription = this.pendingSubscription();
    return pendingSubscription === null && !!activeSubscription && activeSubscription.plan === ConsumptionPlan.free;
  });

  createSubscription(data: CreateSubscriptionRequestDto) {
    return this.authHttpClient.post<{ subscriptionId: bigint }>(`${environment.apiBaseUrl}/subscriptions`, data).pipe(
      tap(() => {
        this.getSubscriptions();
      })
    );
  }

  async getSubscriptions() {
    await firstValueFrom(this.authHttpClient.get<SubscriptionDto[]>(`${environment.apiBaseUrl}/subscriptions`)).then(
      (data) => {
        this.subscriptions.set(data);
      }
    );

    return this.subscriptions;
  }

  /**
   * Returns multiple states
   * - undefined if is loading
   * - null if there are subscriptions but none are pending
   * - SubscriptionDto if there is a pending subscription
   */
  pendingSubscription = computed(() => {
    const subscriptions = this.subscriptions();
    if (subscriptions.length === 0) {
      return undefined;
    }
    return this.subscriptions()?.find((s) => s.status === SubscriptionStatus.pending_upgrade) ?? null;
  });

  /**
   * Returns multiple states
   * - undefined if is loading
   * - null if there are subscriptions but none are active
   * - SubscriptionDto if there is an active subscription
   */
  activeSubscription = createComputed(() => {
    const subscriptions = this.subscriptions();
    if (subscriptions.length === 0) {
      return undefined;
    }
    return (
      this.subscriptions()?.find(
        (s) => s.status === SubscriptionStatus.active || s.status === SubscriptionStatus.trial
      ) ?? null
    );
  });

  getActiveSubscriptionUsage() {
    return this.activeSubscriptionUsageData;
  }

  constructor() {
    effect(
      () => {
        const activeSubscriptionId = this.activeSubscription()?.id;
        if (activeSubscriptionId) {
          firstValueFrom(
            this.authHttpClient.get<GetSubscriptionUsageDto>(
              `${environment.apiBaseUrl}/subscriptions/${activeSubscriptionId}/usage`
            )
          ).then((data) => {
            const mappedData = data.productUsage.map((product) => {
              return {
                ...product,
                productNames: product.productNames.map((name) => mappedName[name])
              };
            });
            this.activeSubscriptionUsageData.set({
              ...data,
              productUsage: mappedData,
              renewalDate: new Date(data.renewalDate)
            });
          });
        }
      },
      { allowSignalWrites: true }
    );
  }

  cancelSubscription(subscriptionId: bigint) {
    return this.authHttpClient.patch(`${environment.apiBaseUrl}/subscriptions/${subscriptionId}/cancel`, {});
  }
}
